import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function FullFatPage() {
  return (
    <TicketshopPage
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-name-filter="full fat" data-fixr-shop-id="be31447a-9d7b-4108-bde6-c9cb9a5287e1" data-fixr-theme="light"></script>`}
      imageSrc="/logo/full-fat-logo.png"
      backgroundSrc="/hero/full-fat-hero.jpg"
      imageMaxWidth="322px"
      title="FULL FAT"
      description={
        <>
          <p>
            Our FULL FAT fuelled student rave takes to the 2 floors of The Old
            Red Bus Station for another year of sell out smashers, jam packed
            with guaranteed vibes & mint people getting sweaty under our low
            ceiling. Get down to the only weekly rave in ORBS with 3 rooms of
            banging tunes & free donuts... an excellent source of vitamin
            D(ance)!
          </p>
          <p>100% fresh. No artificial sounds. Contains good vibes.</p>
        </>
      }
    />
  );
}
